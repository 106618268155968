import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { getCurrencySymbol, getPlanPrice } from "@/Pages/Config/Account/billingHelper";
import { useGetPaymentLink } from "@/Api/genApi";
import { BillingPlan, BillingPlanCurrency, BillingPlanPaymentTerm } from "@/Api/genApi.schemas";
import { Button } from "@/Components/Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";

interface ChangePlanModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export function BuyGodeskModal(props: ChangePlanModalProps) {
	const paymentLinkReq = useGetPaymentLink();

	const { handleSubmit, reset, control, watch } = useForm<BillingPlan>({
		defaultValues: {
			agentCount: 3,
			paymentTerm: BillingPlanPaymentTerm.MONTHLY,
			currency: BillingPlanCurrency.GBP
		}
	});

	const modalStyle = {
		padding: "4px",
		maxWidth: "350px",
		minWidth: "450px",
		maxHeight: "650px",
		border: "none",
		borderRadius: "10px",
		boxShadow: "0 1px 6px -3px rgba(0,0,0, 0.5)",
	};

	function close() {
		reset();
		props.closeModal();
	}

	async function onSubmit(data: BillingPlan) {
		paymentLinkReq.mutate({ data: data }, {
			onSuccess: response => window.location.assign(response),
			onError: error => toast.error("Could not activate: " + error.message)
		});
	}

	const currentAgentCount = watch("agentCount");
	const currentCurrency = watch("currency");
	const currentPaymentTerm = watch("paymentTerm");

	const currentCurrencySymbol = getCurrencySymbol(currentCurrency);
	const currentSeatPrice = getPlanPrice(currentCurrency, currentPaymentTerm);

	const totalPrice = currentSeatPrice * currentAgentCount;

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			modalStyle={modalStyle}
			title="Activate GoDesk"
		>

			<ModalContent>
				<form onSubmit={handleSubmit(onSubmit)} className="pb-2">
					<div className="text-base">Choose the plan that's right for you.</div>

					<div className="my-6">
						<div className="w-72">
							<W_TextInput
								control={control}
								dataname="agentCount"
								label="Agents"
								mandatory
								type={InputTypes.Number}
								min={1}
								max={100}
							/>
						</div>

						<div className="w-72">
							<W_Dropdown<BillingPlanCurrency>
								options={[
									{ label: "$ USD - United States dollar", value: BillingPlanCurrency.USD },
									{ label: "£ GBP - Pound sterling", value: BillingPlanCurrency.GBP },
									{ label: "€ EUR - Euro", value: BillingPlanCurrency.EUR }
								]}
								control={control}
								dataname="currency"
								mandatory
								label="Currency"
							/>
						</div>

						<div className="w-72">
							<W_Dropdown<BillingPlanPaymentTerm>
								options={[
									{ label: "Monthly", value: BillingPlanPaymentTerm.MONTHLY },
									{ label: "Yearly", value: BillingPlanPaymentTerm.YEARLY },
								]}
								control={control}
								dataname="paymentTerm"
								mandatory
								label="Billing cycle"
								toolTip="10% Discount on yearly subscription"
							/>
						</div>
					</div>

					<div className="flex flex-row w-full place-content-between font-semibold border-y border-t-gray-300 border-b-gray-300 py-4">
						<div className="grid grid-cols-4 grid-rows-2 gap-x-3 gap-y-3">
							<div className="text-base">Plan</div>
							<div className="text-base"></div>
							<div className="text-base">Agents</div>
							<div className="text-base"></div>
							<div className="text-base">{currentCurrencySymbol + currentSeatPrice}</div>
							<div className="text-base">×</div>
							<div className="text-base">{currentAgentCount}</div>
							<div className="text-base self-center">=</div>
						</div>
						<div className="self-end text-lg tabular-nums">
							{"Total: " + currentCurrencySymbol + totalPrice}
						</div>
					</div>
				</form>
			</ModalContent>

			<ModalButtons>
				<Button btnClass="btn-grey"label="Cancel" onClick={close} />

				<Button
					btnClass="btn-blue"
					className="px-4"
					onClick={() => handleSubmit(onSubmit)()}
					label="Confirm & purchase"
					disabled={paymentLinkReq.isPending}
					loading={paymentLinkReq.isPending}
				/>
			</ModalButtons>
		</Modal>
	);
}
