"use client";
import { createTheme, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
	interface Palette {
		neutral?: PaletteColorOptions;
	}
	interface PaletteOptions {
		neutral?: PaletteColorOptions;
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		neutral: true;
	}
}

export const defaultTheme = getMuiTheme(false);

export function getMuiTheme(darkMode: boolean) {
	return createTheme({
		spacing: 8,

		palette: {
			mode: darkMode && process.env.NODE_ENV == "development" ? "dark" : "light",

			...(darkMode && process.env.NODE_ENV == "development"
				? {
					// palette values for dark mode
					background: {
						default: "#2d2e2f",
						paper: "#2f2f2f"
					},
					text: {
						primary: "#f9fafa"
					},
					neutral: {
						main: "#d0d0d0"
					},
				}
				:
				{
					// palette values for light mode
					background: {
						default: "#f8f8f8",
						paper: "white"
					},
					neutral: {
						main: "#1F1F1F"
					},
				}),
		},
		typography: {
			fontFamily: '"Inter", sans-serif',
			button: {
				textTransform: "unset", // Stops buttons being uppercase.
			},
			body1: {
				fontSize: 14 // Makes body text smaller.
			},
			body2: {
				fontSize: 12
			}
		},
		components: {
			MuiButtonBase: {
				defaultProps: {
					// disableRipple: true, // Disable distracting animations
				},
			},
			MuiTooltip: {
				defaultProps: {
					arrow: true, // Adds an arrow to the tooltips.
				},
				styleOverrides: {
					tooltip: {
						maxWidth: "20rem", // Makes sure tooltips wrap.
						fontSize: "0.8rem" // Makes tooltips a readable size!
					}
				}
			},
		},
	});
}
